import { createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';
import isString from 'lodash/isString';
import { pages as initialState } from '../initialState';
import { buildAsyncReducers } from '../thunkTemplate';
import { navigate, handleWebsocketMessage } from './_pages';

const { reducer, actions } = createSlice({
  name: 'pages',
  initialState,
  reducers: {
    setLoader(state, action) {
      state.loaderOpen = action.payload;
    },
    setWebsocketOrgId(state, action) {
      state.websocket.orgId = action.payload;
    },
    setWebsocketLastUpdate(state, action) {
      state.websocket.lastUpdate = action.payload;
    },
    setPage(state, action) {
      const page = action.payload.page;

      let id = isString(get(action, 'payload.id'))
        ? action.payload.id
        : state[page].id;
      let tab = isString(get(action, 'payload.tab'))
        ? action.payload.tab
        : state[page].tab;
      let view = isString(get(action, 'payload.view'))
        ? action.payload.view
        : state[page].view;

      let expectationType = isString(get(action, 'payload.expectationType'))
        ? action.payload.expectationType
        : state[page]?.expectationType;

      state[page] = { id, tab, view, expectationType };
    },
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [navigate, handleWebsocketMessage]);
  },
});

const { setWebsocketOrgId, setWebsocketLastUpdate, setLoader, setPage } =
  actions;
export {
  setWebsocketOrgId,
  setWebsocketLastUpdate,
  setLoader,
  navigate,
  setPage,
  handleWebsocketMessage,
};
export default reducer;
