import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import find from 'lodash/find';
import get from 'lodash/get';
import head from 'lodash/head';
import remove from 'lodash/remove';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { updateNodes } from '../nodes';
import { updateTimeseriesData } from '../timeseries';
import { toastr } from '../../components/CustomToast';

const getMeters = createAsyncThunk(
  'meters/getMeters',
  async (_, { getState, requestId }) => {
    const { currentRequestId, loading } = getState().meters;
    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    const meters = await new WebAPIClient().GET('/resource/meters');
    return { data: meters };
  }
);

const putMeter = createAsyncThunk(
  'meters/putMeter',
  async (meter, { dispatch, getState, requestId }) => {
    const { currentRequestId, loading, data: allMeters } = getState().meters;
    let meters = cloneDeep(allMeters);

    try {
      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());

      let updatedMeter = await new WebAPIClient().PUT(
        `/resource/meters/${meter.org_id}/${meter.meter_id}`,
        meter
      );
      remove(meters, { meter_id: get(updatedMeter, 'meter_id') });
      meters = concat(meters, updatedMeter);
      toastr.success({
        title: 'Meter updated',
        message: get(updatedMeter, 'name'),
      });
      dispatch(updateNodes({ meters }));

      return { data: meters };
    } catch (err) {
      toastr.error({
        title: 'Error',
        message: err.message,
      });
    } finally {
      dispatch(hideLoading());
    }
  }
);

const handleSuccess = createAsyncThunk(
  'meters/handleSuccess',
  async (payload, { dispatch, getState }) => {
    try {
      const { data: allMeters } = getState().meters;

      let meters = cloneDeep(allMeters);

      dispatch(showLoading());
      const orgId = payload.org_id;
      const meterId = payload.meter_id;
      const lastOkTime = payload.last_ok_time;
      const timestamp = payload.timestamp / 1000;
      const demand = payload.demand;

      // update meter
      let meter = find(meters, { meter_id: meterId, org_id: orgId });
      meter.stats.last_ok_time = lastOkTime;
      meter.stats.successes += 1;
      dispatch(updateTimeseriesData({ orgId, meterId, demand, timestamp }));

      remove(meters, { meter_id: meterId, org_id: orgId });
      return { data: concat(meters, meter) };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const handleFailure = createAsyncThunk(
  'meters/handleFailure',
  async (payload, { dispatch, getState }) => {
    try {
      const { data: meters } = getState().meters;
      const allMeters = cloneDeep(meters);
      let meter = head(
        remove(allMeters, {
          meter_id: payload.meter_id,
          org_id: payload.org_id,
        })
      );

      if (meter) {
        meter.stats.last_fail_time = payload.last_fail_time;
        meter.stats.failures += 1;
        meter.stats.last_fail_message = payload.message;
        return { data: concat(allMeters, meter) };
      }
      return {};
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getMeters, putMeter, handleSuccess, handleFailure };
