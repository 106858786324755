import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import head from 'lodash/head';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import remove from 'lodash/remove';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { buildAsyncReducers } from '../thunkTemplate';
import { kiosk } from '../initialState';
import { setLoader } from '../pages';
import { RANGES } from '../../components/charts/selectors/SelectRange';

const getKioskData = createAsyncThunk(
  'kiosk/getKioskData',
  async (_, { dispatch, getState, requestId }) => {
    const { kioskCode, type, current, currentRequestId, loading } =
      getState().kiosk;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    try {
      let resource = null;
      let sites = null;

      const payload = await new WebAPIClient().GET(
        `/kiosk/${type}/${kioskCode}`
      );

      if (type === 'organization') {
        resource = get(payload, 'organization', {});
        sites = sortBy(get(payload, 'sites', []), 'name');
      } else if (type === 'site') {
        const site = get(payload, 'site', {});
        resource = site;
        sites = [site];
      }

      return {
        lastUpdate: dayjs(),
        validLicense: true,
        resource,
        sites,
        meters: sortBy(get(payload, 'meters', []), 'name'),
        current: {
          ...current,
          timezone: { ...resource.timezone },
        },
      };
    } catch (err) {
      errorResponseToastr(err);
      return { validLicense: false };
    } finally {
      dispatch(setLoader(false));
    }
  }
);

const getLastMonthData = createAsyncThunk(
  'kiosk/getLastMonthData',
  async (props, { dispatch }) => {
    try {
      dispatch(showLoading());
      if (props.type === 'org') props.type = 'organization';
      let month = dayjs().subtract(1, 'month').startOf('month');

      let _rawData = await Promise.all(
        map(props.meters, (meter) =>
          new WebAPIClient().GET(
            `/kiosk/${props.type}/${props.kioskCode}/${
              meter.meter_id
            }/${month.format('YYYY-MM')}`
          )
        )
      );

      return { lastMonth: { rawData: _rawData } };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getKioskChartData = createAsyncThunk(
  'kiosk/getKioskChartData',
  async (newRange, { dispatch, getState, requestId }) => {
    const {
      kioskCode,
      type,
      meters,
      currentRequestId,
      loading,
      current,
      resource,
    } = getState().kiosk;

    if (loading !== true || requestId !== currentRequestId) {
      return;
    }

    let defaultRange = RANGES[6];
    const end = dayjs().tz(resource.timezone.zone).endOf('day');
    const start = end.subtract(3, 'day').startOf('day');
    defaultRange = { ...defaultRange, start, end };

    let _range = newRange || defaultRange;
    try {
      dispatch(showLoading());
      let _type = type;
      if (_type === 'org') _type = 'organization';

      let rawData = await Promise.all(
        map(meters, (meter) =>
          new WebAPIClient().GET(
            `/kiosk/${_type}/${kioskCode}/${
              meter.meter_id
            }/${_range.start.unix()}/${_range.end.unix()}`
          )
        )
      );
      return { current: { ...current, rawData, range: _range } };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const { actions, reducer } = createSlice({
  name: 'kiosk',
  initialState: kiosk,
  reducers: {
    setKioskTypeCode: (state, { payload }) => {
      return {
        ...state,
        kioskCode: payload.kioskCode,
        type: payload.type,
      };
    },
    setImageIndex: (state, { payload }) => {
      let idx = null;

      if (state.images.selectedIdx === payload - 1) {
        idx = 0;
      } else {
        idx = state.images.selectedIdx + 1;
      }

      return {
        ...state,
        images: {
          ...state.images,
          selectedIdx: idx,
        },
      };
    },
    setSelectedEnergy: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          selectedEnergy: payload,
        },
      };
    },
    setSlideHeight: (state, { payload }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          slideHeight: payload,
        },
      };
    },
    setCurrentTimezone: (state, { payload }) => {
      return {
        ...state,
        current: { ...state.current, timezone: payload },
      };
    },
    setLastMonthDate: (state, { payload }) => {
      return {
        ...state,
        lastMonth: { ...state.lastMonth, date: payload },
      };
    },
    handleSuccessKiosk: (state, { payload }) => {
      let range = cloneDeep(state.current.range);
      let rawData = cloneDeep(state.current.rawData);

      const value = payload.demand;
      const recordDayjs = dayjs.unix(payload.timestamp / 1000);

      if (typeof value === 'number' && range?.end.isSame(recordDayjs, 'day')) {
        const meterRawData = head(
          remove(
            rawData,
            (meterData) => meterData.meter_id === payload.meter_id
          )
        );
        meterRawData?.records?.push({
          timestamp: payload.timestamp / 1000,
          value: payload.demand,
        });
        if (meterRawData) {
          rawData = concat(meterRawData, rawData);
        }
      }
      return { ...state, current: { ...state.current, rawData } };
    },
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getKioskData,
      getKioskChartData,
      getLastMonthData,
    ]);
  },
});

const {
  setKioskTypeCode,
  setImageIndex,
  setSelectedEnergy,
  setSlideHeight,
  handleSuccessKiosk,
  setCurrentTimezone,
  setLastMonthDate,
} = actions;

export {
  setKioskTypeCode,
  getKioskData,
  getKioskChartData,
  getLastMonthData,
  setImageIndex,
  setSelectedEnergy,
  setSlideHeight,
  handleSuccessKiosk,
  setCurrentTimezone,
  setLastMonthDate,
};
export default reducer;
