import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import remove from 'lodash/remove';
import dayjs from 'dayjs';

import WebAPIClient, { errorResponseToastr } from '../../api';
import { showLoading, hideLoading } from '../app';
import { getMeters } from '../meters';
import { getSites } from '../sites';
import { getMemberships } from '../memberships';
import { setLoader, setWebsocketLastUpdate } from '../pages';
import { getLicenses } from '../licenses';
import { getAlarms } from '../alarms';
import { toastr } from '../../components/CustomToast';

const getOrganizations = createAsyncThunk(
  'organization/getOrganizations',
  async (user, { dispatch, getState, requestId }) => {
    try {
      const { loading, currentRequestId } = getState().organizations;

      if (!user) {
        const { item: data } = getState().user;
        user = { ...data };
      }

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(getSites());
      dispatch(getMeters());
      dispatch(getMemberships());
      dispatch(getLicenses());
      dispatch(getAlarms());

      const organizations = await new WebAPIClient().GET(
        '/resource/organizations'
      );
      const portfolioMemberships = await new WebAPIClient().GET(
        '/resource/portfolio_memberships'
      );
      dispatch(setWebsocketLastUpdate(dayjs()));
      return {
        data: organizations,
        portfolioMemberships,
      };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(setLoader(false));
    }
  }
);

const putOrganization = createAsyncThunk(
  'organization/putOrganization',
  async (organization, { dispatch, getState, requestId }) => {
    try {
      const {
        currentRequestId,
        loading,
        data: organizations,
      } = getState().organizations;

      if (loading !== true || requestId !== currentRequestId) {
        return;
      }

      dispatch(showLoading());
      let _organizations = cloneDeep(organizations);

      const updatedOrganization = await new WebAPIClient().PUT(
        `/resource/organizations/${organization.org_id}`,
        organization
      );
      const organization_id = get(updatedOrganization, 'org_id');
      remove(_organizations, { org_id: organization_id });

      toastr.success({
        title: 'Organization updated',
      });
      return {
        data: concat(_organizations, updatedOrganization),
      };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getOrganizations, putOrganization };
