import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const toastr = {
  success: (props) => {
    toast.success(CustomToast, {
      data: props,
    });
  },
  error: (props) => {
    toast.error(CustomToast, {
      data: props,
    });
  },
  info: (props) => {
    toast.info(CustomToast, {
      data: props,
    });
  },
  warning: (props) => {
    toast.warn(CustomToast, {
      data: props,
    });
  },
};

function CustomToast(props) {
  const { data } = props;
  return (
    <Box>
      <Typography variant='h6' fontWeight='bold'>
        {data.title}
      </Typography>
      {data.message && <Typography variant='body1'>{data.message}</Typography>}
      {data.messages &&
        data.messages.map((item, key) => (
          <Typography key={key} variant='body1'>
            • {item}
          </Typography>
        ))}
    </Box>
  );
}

CustomToast.propTypes = {
  data: PropTypes.object,
};

export default CustomToast;

export { toastr };
