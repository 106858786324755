import { createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import get from 'lodash/get';
import remove from 'lodash/remove';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';
import { toastr } from '../../components/CustomToast';

const getMemberships = createAsyncThunk(
  'memberships/getMemberships',
  async () => {
    try {
      const memberships = await new WebAPIClient().GET('/resource/memberships');
      return { data: memberships };
    } catch (err) {
      console.error(err);
    }
  }
);

const postMembership = createAsyncThunk(
  'memberships/postMembership',
  async (membership, { dispatch, getState }) => {
    const { data: orgUsers } = getState().memberships;
    let _orgUsers = cloneDeep(orgUsers);

    try {
      dispatch(showLoading());
      let { membership: newMembership } = await new WebAPIClient().POST(
        `/resource/memberships`,
        membership
      );

      toastr.success({
        title: 'User Membership created',
      });
      return { data: concat(_orgUsers, newMembership) };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const putMembership = createAsyncThunk(
  'memberships/putMembership',
  async (membership, { dispatch, getState }) => {
    const { data: orgUsers } = getState().memberships;
    let _orgUsers = cloneDeep(orgUsers);

    try {
      dispatch(showLoading());
      let updatedMembership = await new WebAPIClient().PUT(
        `/resource/memberships/${membership?.org_id}/${membership?.user_id}`,
        membership
      );

      remove(_orgUsers, {
        org_id: get(updatedMembership, 'org_id'),
        user_id: get(updatedMembership, 'user_id'),
      });

      toastr.success({
        title: 'User permissions updated',
      });

      return { data: concat(_orgUsers, updatedMembership) };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const deleteMembership = createAsyncThunk(
  'memberships/deleteMembership',
  async (membership, { dispatch, getState }) => {
    const { data: orgUsers } = getState().memberships;
    let _orgUsers = cloneDeep(orgUsers);
    const org_id = get(membership, 'org_id');
    const user_id = get(membership, 'user_id');

    try {
      dispatch(showLoading());
      await new WebAPIClient().DELETE(
        `/resource/memberships/${org_id}/${user_id}`
      );

      remove(_orgUsers, {
        org_id: get(membership, 'org_id'),
        user_id: get(membership, 'user_id'),
      });

      toastr.success({
        title: 'Removed user from Organization',
      });
      return { data: _orgUsers };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getMemberships, postMembership, putMembership, deleteMembership };
