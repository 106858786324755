import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import head from 'lodash/head';
import remove from 'lodash/remove';
import debounce from 'lodash/debounce';

import { timeseries as initialState } from '../initialState';
import { refreshMeterTimeseries, refreshMeterPeaks } from './_timeseries';
import { buildAsyncReducers } from '../thunkTemplate';

let pendingUpdates = [];
const BATCH_DELAY = 1000; // milliseconds - adjust as needed

const processBatchedUpdates = debounce((dispatch) => {
  if (pendingUpdates.length === 0) return;

  const updates = [...pendingUpdates];
  pendingUpdates = []; // Clear the queue
  dispatch(_updateTimeseriesDataBatch(updates));
}, BATCH_DELAY);

// NOTE: "Mutating" state is safe in redux toolkit because it uses Immer
const { reducer, actions } = createSlice({
  name: 'timeseries',
  initialState,
  reducers: {
    _updateTimeseriesDataBatch: (state, { payload: updates }) => {
      let allTimeseries = cloneDeep(state.data);
      const now = dayjs();

      // Group updates by meterId and orgId
      const groupedUpdates = updates.reduce((acc, update) => {
        const key = `${update.orgId}-${update.meterId}`;
        if (!acc[key]) {
          acc[key] = {
            device_id: update.meterId,
            org_id: update.orgId,
            updates: [],
          };
        }
        acc[key].updates.push({
          timestamp: update.timestamp,
          value: update.demand,
        });
        return acc;
      }, {});

      Object.values(groupedUpdates).forEach(
        ({ device_id, org_id, updates }) => {
          let timeseries = head(
            remove(allTimeseries, {
              device_id,
              org_id,
              data_type: 'recent',
            })
          );

          if (timeseries?.data) {
            timeseries.data = concat(timeseries.data, updates);
            timeseries.lastRefresh = now;
            allTimeseries = concat(allTimeseries, timeseries);
          }
        }
      );

      state.data = allTimeseries;
    },
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [refreshMeterTimeseries, refreshMeterPeaks]);
  },
});

const updateTimeseriesData = (update) => (dispatch) => {
  pendingUpdates.push(update);
  processBatchedUpdates(dispatch);
};

// Extract each action creator by name
const { _updateTimeseriesDataBatch } = actions;

// Export the reducer, either as a default or named export
export { updateTimeseriesData, refreshMeterTimeseries, refreshMeterPeaks };
export default reducer;
