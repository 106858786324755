import { createSlice } from '@reduxjs/toolkit';
import {
  authenticateUser,
  logoutUser,
  createSubscriptions,
  deleteSubscriptions,
} from './_users';

import { user as initialState } from '../initialState';
import { buildAsyncReducers } from '../thunkTemplate';

const { reducer, actions } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.item = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      authenticateUser,
      logoutUser,
      createSubscriptions,
      deleteSubscriptions,
    ]);
  },
});

const { setUser } = actions;
export {
  authenticateUser,
  logoutUser,
  setUser,
  createSubscriptions,
  deleteSubscriptions,
};
export default reducer;
