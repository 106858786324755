import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

function lastMonth(zoneName) {
  const lastMonth = dayjs().tz(zoneName).subtract(1, 'month');
  return { start: lastMonth.startOf('month'), end: lastMonth.endOf('month') };
}

function thisMonth(zoneName) {
  const thisMonth = dayjs().tz(zoneName);
  return { start: thisMonth.startOf('month'), end: thisMonth.endOf('month') };
}

function lastWeek(zoneName) {
  const lastWeek = dayjs().tz(zoneName).subtract(1, 'week');
  return { start: lastWeek.startOf('week'), end: lastWeek.endOf('week') };
}

function thisWeek(zoneName) {
  const thisWeek = dayjs().tz(zoneName);
  return { start: thisWeek.startOf('week'), end: thisWeek.endOf('week') };
}

function yesterday(zoneName) {
  const yesterday = dayjs().tz(zoneName).subtract(1, 'day');
  return { start: yesterday.startOf('day'), end: yesterday.endOf('day') };
}

function today(zoneName) {
  const today = dayjs().tz(zoneName);
  return { start: today.startOf('day'), end: today.endOf('day') };
}

function lastYear(zoneName) {
  const lastYear = dayjs().tz(zoneName).subtract(1, 'year');
  return { start: lastYear.startOf('year'), end: lastYear.endOf('year') };
}

function thisYear(zoneName) {
  const thisYear = dayjs().tz(zoneName);
  return { start: thisYear.startOf('year'), end: thisYear.endOf('year') };
}

const getLatestInterval = () => {
  let now = dayjs();
  return now
    .minute(now.minute() - (now.minute() % 15))
    .second(0)
    .millisecond(0);
};

const dateStringToDayJS = (dateString) => {
  return dayjs(dateString?.substring(0, 19) + dateString?.slice(-6));
};

const formatTimestamp = (unixTime, timezone, format = 'MMM Do h:mm A') => {
  if (timezone.zone) {
    return dayjs.unix(unixTime).tz(timezone.zone).format(format);
  }
  return dayjs.unix(unixTime).format(format);
};

export {
  today,
  yesterday,
  lastWeek,
  thisMonth,
  lastMonth,
  lastYear,
  thisYear,
  thisWeek,
  getLatestInterval,
  dateStringToDayJS,
  formatTimestamp,
};
