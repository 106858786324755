import { createAsyncThunk } from '@reduxjs/toolkit';
import find from 'lodash/find';

import { showLoading, hideLoading } from '../app';
import WebAPIClient, { errorResponseToastr } from '../../api';

const getRTOs = createAsyncThunk('rtos/getRTOs', async (_, { dispatch }) => {
  try {
    dispatch(showLoading());
    let rtos = await new WebAPIClient().GET('/resource/rtos');
    const selectedRTO = find(rtos, { rto_id: 'PJM' });
    return { data: rtos, selectedRTO };
  } catch (err) {
    errorResponseToastr(err);
  } finally {
    dispatch(hideLoading());
  }
});

const getInstLoadData = createAsyncThunk(
  'rtos/getInstLoadData',
  async ({ start, end }, { dispatch, getState, requestId }) => {
    const { selectedRTO, loading, currentRequestId } = getState().rtos;

    if (!loading || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());
      let records = await new WebAPIClient().GET(
        `/resource/inst_loads/${selectedRTO.rto_id}`,
        {
          start: start.unix(),
          end: end.unix(),
        }
      );

      return { instLoadData: records };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

const getPeakData = createAsyncThunk(
  'rtos/getPeakData',
  async (_, { dispatch, getState, requestId }) => {
    const { selectedRTO, loading, currentRequestId } = getState().rtos;
    if (!loading || requestId !== currentRequestId) {
      return;
    }

    try {
      dispatch(showLoading());
      let peaks = await new WebAPIClient().GET(
        `/resource/peaks/${selectedRTO.rto_id}`
      );
      return { peaks };
    } catch (err) {
      errorResponseToastr(err);
    } finally {
      dispatch(hideLoading());
    }
  }
);

export { getRTOs, getInstLoadData, getPeakData };
