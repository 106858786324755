import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid2';

const Container = ({ children }) => {
  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      sx={{ pt: 2, mb: 2 }}>
      <Grid size={{ xs: 12, lg: 8, xl: 6 }}>
        <div>{children}</div>
      </Grid>
    </Grid>
  );
};

Container.propTypes = {
  children: PropTypes.object,
};

export default Container;
