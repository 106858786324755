import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider, useSelector } from 'react-redux';
import { HistoryRouter } from 'redux-first-history/rr6';
import { ToastContainer } from 'react-toastify';
import get from 'lodash/get';

import createTheme from '@mui/material/styles/createTheme';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import AppRoutes from './routes';
import { generateTheme } from './styles/muiTheme';
import { history, store } from './store/createStore';
import Backdrop from './components/Loaders/Backdrop';
import ErrorBoundary from './components/ErrorBoundary';

import './styles/index.css';

function App() {
  const resource = useSelector((state) => state.kiosk.resource);

  const theme = React.useMemo(() => {
    const primary = get(resource, 'theme.primary');
    const secondary = get(resource, 'theme.secondary');
    return createTheme(generateTheme(primary, secondary));
  }, [resource]);

  return (
    <ThemeProvider theme={theme} defaultMode='light'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ErrorBoundary>
          <HelmetProvider>
            <Helmet>
              <title>Gridcap</title>
              <meta
                name='viewport'
                content='width=device-width, initial-scale=1'
              />
            </Helmet>
            <ToastContainer
              position='bottom-right'
              autoClose={10000}
              theme='colored'
            />
            <Backdrop />
            <AppRoutes />
          </HelmetProvider>
        </ErrorBoundary>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

function AppContainer() {
  return (
    <React.StrictMode>
      <ReduxProvider store={store}>
        <HistoryRouter history={history}>
          <App />
        </HistoryRouter>
      </ReduxProvider>
    </React.StrictMode>
  );
}

export default AppContainer;
