import { createSlice } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';

import { buildAsyncReducers } from '../thunkTemplate';
import { alarms as initialState } from '../initialState';
import {
  getAlarms,
  getAllAlarms,
  acknowledgeAlarm,
  closeAlarm,
  deleteAlarm,
  handleInsertAlarm,
  handleModifyAlarm,
  createTriggerDevices,
  updateTriggerDevices,
  deleteTriggerDevices,
} from './_alarms';

const { reducer, actions } = createSlice({
  name: 'alarms',
  initialState,
  reducers: {
    handleRemoveAlarm: (state, action) => {
      let allAlarms = cloneDeep(state.data);
      remove(allAlarms, { alarm_id: action.payload.alarm_id });
      state.data = allAlarms;
    },
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getAlarms,
      getAllAlarms,
      acknowledgeAlarm,
      closeAlarm,
      deleteAlarm,
      handleInsertAlarm,
      handleModifyAlarm,
      createTriggerDevices,
      updateTriggerDevices,
      deleteTriggerDevices,
    ]);
  },
});

const { handleRemoveAlarm } = actions;

export {
  getAlarms,
  getAllAlarms,
  acknowledgeAlarm,
  closeAlarm,
  deleteAlarm,
  handleInsertAlarm,
  handleModifyAlarm,
  handleRemoveAlarm,
  createTriggerDevices,
  updateTriggerDevices,
  deleteTriggerDevices,
};
export default reducer;
