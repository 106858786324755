import { createSlice } from '@reduxjs/toolkit';

import { getMeters, putMeter, handleSuccess, handleFailure } from './_meters';
import { meters as initialState } from '../initialState';
import { buildAsyncReducers } from '../thunkTemplate';

const { reducer, actions } = createSlice({
  name: 'meters',
  initialState,
  reducers: {
    setMeters: (state, { payload }) => ({
      ...state,
      data: payload,
    }),
  },
  extraReducers: (builder) => {
    buildAsyncReducers(builder, [
      getMeters,
      putMeter,
      handleSuccess,
      handleFailure,
    ]);
  },
});

const { setMeters } = actions;

export { getMeters, putMeter, setMeters, handleSuccess, handleFailure };
export default reducer;
